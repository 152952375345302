<template>
  <div class="hello">
    <div class="middle">
      <div class="chat">
        <div>
          <p>无锡中铠信息咨询服务有限公司</p>
          <p>热线：400-859-1580 </p>
        </div>
        <div style="position: relative;">
          <div v-if="showChat" class="code">
            <img src="../assets/us.png" alt="">
          </div>
          <img @mouseover="mouseOver" @mouseleave="mouseLeave" :src="weChat" alt="" style="margin-right:8px">
        </div>
      </div>
      <div class="chat">
        <p>地址：无锡市滨湖区锦溪路100号科教创业园3号楼1楼</p>
        <div class="tips">
          <span @click="$router.push('/agreement')" class="fan">用户协议</span>
          <span @click="$router.push('/privacy')" class="fan">隐私政策</span>
          <span>关注我们</span>
        </div>
      </div>
      <div class="line"></div>
      <div class="bottom">COPYRIGHT © 2009-2018 经营许可证编号：<a href="https://beian.miit.gov.cn/">苏B2-20162058</a></div>
      <div class="bottom">无锡中铠信息咨询服务有限公司 <a href="https://beian.miit.gov.cn/">苏ICP备12060410号-1</a></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FootBox',
    data() {
      return {
        weChat: require('../assets/icon_wechat_normal.png'),
        showChat: false
      }
    },
    methods: {
      mouseOver() {
        this.weChat = require('../assets/icon_wechat_focused.png')
        this.showChat = true
      },
      mouseLeave() {
        this.weChat = require('../assets/icon_wechat_normal.png')
        this.showChat = false
      }
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .hello {
    width: 100%;
    height: 180px;
    background: #333333;
  }

  .middle {

    margin: auto;
    padding-top: 20px;
    width: 660px;
  }

  .chat {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
      margin-bottom: 8px;
      text-align: left;
      font-size: 10px;
      color: #D6D6D6;
    }

    img {
      margin-right: 5px;
      width: 40px;
      height: 30px;
    }
  }

  .tips {
    font-size: 14px;
    color: #AAAAAA;
    cursor: default;

    span {
      margin-left: 40px;
    }

    .fan:hover {
      color: #fff;
    }
  }

  .line {
    margin: 15px 0 15px;
    width: 100%;
    height: 1px;
    background: #575757;
  }

  .bottom {
    font-size: 8px;
    color: #AAAAAA;
    text-align: center;

    a {
      color: #AAAAAA;
      text-decoration: none;
    }
  }

  .code {
    position: absolute;
    top: -110px;
    left: -30px;
    width: 100px;
    height: 100px;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3100);
    z-index: 2;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

</style>
