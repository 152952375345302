<template>
  <div class="box">
    <div class="middle">
      <div class="side" :style="{'top':topHeight+'px'}">
        <div class="vip" @mouseover="mouseover" @mouseout="mouseout" @click="pageClick('member','','律兜会员')">
          <img v-if="showVipPic" class="logo" src="../assets/icon_vip_focused.png" alt="">
          <img v-else class="logo" src="../assets/icon_vip_normal.png" alt="">
          <div class="name">律兜会员</div>
          <div class="buy" v-show="showVip" @mouseover="setTimes=null" @mouseout='showVip=false'>
            <div class="btn">
              <div class="first">首月仅38元</div>
              开通会员
            </div>
          </div>
        </div>
        <div class="vip" @mouseover="showChat=true" @mouseout="showChat=false" @click="pageClick('center',2,'律师回复')">
          <img v-if="showChat" class="logo" style="width:21px;" src="../assets/icon_chat_focused.png" alt="">
          <img v-else class="logo" style="width:21px;" src="../assets/icon_chat_normal.png" alt="">
          <div class="name">律师回复</div>
          <div class="num" v-if="msgNum>0">{{msgNum>99?'99+':msgNum}}</div>
        </div>
        <div class="vip" @mouseover="showMe=true" @mouseout="showMe=false" @click="pageClick('center',1,'个人中心')">
          <img v-if="showMe" class="logo" style="width:20px;" src="../assets/icon_me_focused.png" alt="">
          <img v-else class="logo" style="width:20px;" src="../assets/icon_me_normal.png" alt="">
          <div class="name">个人中心</div>
        </div>
        <div class="vip" @mouseover="showCode=true" @mouseout="showCode=false" @click="pageClick('download','','客户端')">
          <img v-if="showCode" class="logo" style="width:20px;" src="../assets/dcode.png" alt="">
          <img v-else class="logo" style="width:20px;" src="../assets/icon_qrcode_normal.png" alt="">
          <div class="name">客户端</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapGetters
  } from "vuex";
  export default {
    props: {
      topHeight: {
        default: 325
      }
    },
    computed: {
      ...mapGetters({
        noreadtotal: 'onnoreadtotal',
      })
    },
    data() {
      return {
        showVip: false,
        setTimes: null,
        showVipPic: false,
        showChat: false,
        showMe: false,
        showCode: false,
        msgNum: 0
      }
    },
    watch: {
      noreadtotal(newVal) {
        console.log(newVal)
        this.msgNum = newVal
      }
    },
    mounted() {
      if (localStorage.getItem('user')) {
        var user = JSON.parse(localStorage.getItem('user'))
        this.msgNum = localStorage.getItem(user.MemberThirdId + '_noreadtotal') ? localStorage.getItem(user
          .MemberThirdId + '_noreadtotal') : 0
      }
    },
    methods: {
      mouseover() {
        this.showVip = true
        this.setTimes = null
        this.showVipPic = true
      },
      mouseout() {
        this.showVipPic = false
        this.setTimes = setTimeout(() => {
          if (this.setTimes) {
            this.showVip = false
            clearTimeout(this.setTimes)
          }
        }, 1000)
      },
      pageClick(path, num, title) {
        _hmt.push(['_trackEvent', 'click', title, 'pc']);
        if (path == 'center') {
          if (!localStorage.getItem('user')) {
            this.$router.push('/login?to=center&id=' + num)
          } else {
            this.$router.push('/center?id=' + num)
          }
        } else {
          this.$router.push(`/${path}`)
        }
      }
    }
  }

</script>

<style scoped lang="scss">
  .box {
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 99;

  }

  .middle {
    position: relative;
  }

  .side {
    position: absolute;
    padding-top: 24px;
    right: -538px;
    width: 58px;
    height: 260px;
    text-align: center;
    background: #FFFFFF;
    border-radius: 0px 14px 14px 0px;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1000);
  }

  .vip {
    margin-bottom: 20px;
    cursor: default;
    position: relative;

    .logo {
      width: 24px;
      height: 20px;
    }

    .name {
      font-size: 12px;
      color: #696662;
    }

    .num {
      width: 19px;
      height: 19px;
      background: #F24537;
      border: 1px solid #FFFFFF;
      border-radius: 50%;
      font-size: 8px;
      color: #FFFFFF;
      text-align: center;
      line-height: 19px;
      position: absolute;
      top: -8px;
      right: 7px;
    }
  }

  .vip:hover {
    .name {
      color: #21D0C8;
    }
  }

  .buy {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    left: -180px;
    top: -24px;
    width: 170px;
    height: 310px;
    background-image: url('../assets/img_vip310.png');
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 99999;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2600);
    border-radius: 10px;
  }

  .btn {
    position: relative;
    margin-bottom: 20px;
    width: 120px;
    height: 40px;
    line-height: 40px;
    background: linear-gradient(-8deg, #E2AB75 0%, #FFD8A2 100%);
    border-radius: 20px;
    font-size: 16px;
    color: #53300C;
    text-align: center;
  }

  .first {
    position: absolute;
    top: -10px;
    right: -13px;
    width: 71px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background: #F24537;
    border-radius: 10px 0px 10px 0px;
  }

</style>
