<template>
  <div class="top">
    <div class="nav">
      <div class="list">
        <img class="logo" src="../assets/icon_logo_blue.png" alt="" @click="$router.push({path:'/index'})">
        <div @click="getPage('/index','','首页')">首页</div>
        <div class="word" @mouseover="showNav=true" @mouseout="showNav=false">
          <img class="hot" src="../assets/icon_hot18.png" alt="">
          <div class="level_box" v-show="showNav">
            <div class="level">
              <div style="font-weight:400" @click="getPage('/index',1,'我要咨询')">图文咨询</div>
              <div style="font-weight:400" @click="getPage('/index',2,'我要咨询')">电话咨询</div>
            </div>
          </div>
        </div>
        <div @click="getPage('/recommend','','律师推荐')">律师推荐</div>
        <div @click="getPage('/contract','','合同模板')">合同模板</div>
      </div>
      <div class="tel" @mouseover="showTime=true" @mouseout="showTime=false">
        <img src="../assets/icon_phine_blue16.png" alt="">
        <span>400-859-1580</span>
        <div class="work" v-show="showTime">8:30 - 17:30</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        showTime: false,
        showNav: false
      }
    },
    methods: {
      getPage(path, res, title) {
        _hmt.push(['_trackEvent', 'click', title, 'pc']);
        if (res) {
          if (localStorage.getItem('user')) {
            this.$router.push({
              path: '/ask',
              query: {
                id: res
              }
            })
          } else {
            this.$router.push('/login')
          }

        } else {
          this.$router.push(`${path}`)
        }
      },
    }
  }

</script>

<style lang="scss" scoped>
  .top {
    height: 60px;
    background: #FFFFFF;
  }

  .nav {
    width: 940px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }

  .list {
    display: flex;
    align-items: center;

    >div {
      position: relative;
      margin-right: 49px;
      line-height: 60px;
      font-size: 18px;
      color: #333333;
      cursor: default;
    }

    div:hover {
      color: #21D0C8;
    }
  }

  .logo {
    margin-right: 60px;
    width: 80px;
    height: 29px;
  }

  .word {
    position: relative;
    width: 71px;
    height: 16px;
    background-image: url('../assets/text_consult_black_normal.png');
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .word:hover {
    background-image: url('../assets/text_consult_selected.png');
  }

  .hot {
    position: absolute;
    top: -8px;
    right: -16px;
    width: 16px;
    height: 18px;
  }

  .tel {
    position: relative;
    cursor: default;

    img {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }

    span {
      line-height: 60px;
      font-size: 16px;
      font-weight: bold;
      color: #21D0C8;
    }
  }

  .work {
    position: absolute;
    top: 40px;
    left: 19px;
    width: 110px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    color: #666666;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2600);
    border-radius: 10px;
    z-index: 999;
  }

  .level_box {
    position: absolute;
    padding: 14px 0;
    left: -8px;
    bottom: -104px;
    z-index: 3;
  }

  .level {
    padding-top: 10px;
    width: 90px;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2600);
    border-radius: 10px;
    box-sizing: border-box;

    div {
      margin: 0 auto 10px;
      width: 80px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      border-radius: 6px;
      color: #AAAAAA;
      font-size: 1px;
    }

    div:hover {
      background: #3CD3CC;
      color: #fff;
    }
  }

</style>
